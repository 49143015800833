<template>
  
  <Navbar />
  <main class="main">
    <router-view/>
  </main>
</template>

<script>
import { defineAsyncComponent } from 'vue'
export default {
      components:{
        Navbar: defineAsyncComponent(() => import('./components/Navbar.vue')),
        // Formulario: defineAsyncComponent(() => import('./components/Formulario.vue'))
    },
}
</script>

<style lang="scss">
.main {
  max-width: 1200px;
  margin: 0 auto;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body{
  margin: 0;
  background-color: #2C687F;   
}
nav {
  padding: 0px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
