// Importa el store Vuex para acceder al estado de autenticación
import store from '../store/index';  // Asegúrate de ajustar la ruta de importación según la estructura de tu proyecto

import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/cuenta',
    name: 'cuenta',
    component: () => import(/* webpackChunkName: "cuenta" */ '../views/Cuenta.vue'),
    meta: { requiresAuth: true }  // Agrega un meta campo para identificar rutas protegidas
  },
  {
    path: '/suscripcion',
    name: 'suscripcion',
    component: () => import(/* webpackChunkName: "suscripcion" */ '../views/Suscripcion.vue'),
    meta: { requiresAuth: true }  // Agrega un meta campo para identificar rutas protegidas
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// beforeEach guard para verificar la autenticación antes de cada navegación
router.beforeEach((to, from, next) => {
  // Verifica si la ruta requiere autenticación
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Verifica el estado de autenticación
    if (!store.getters.isAuthenticated) {
      // Redirige al home si el usuario no está autenticado
      next('/');
    } else {
      // Permite la navegación si el usuario está autenticado
      next();
    }
  } else {
    // Permite la navegación para rutas que no requieren autenticación
    next();
  }
});

export default router;
