import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
    plugins: [createPersistedState()],
    state: {
        user: null,
        isAuthenticated: false,
        cart: [], // Se asegura de que 'cart' siempre se inicialice como un array vacío
    },
    getters: {
        isAuthenticated(state) {
            return !!state.user;  // Simplifica la verificación de autenticación
        },
        username(state) {
            return state.user ? state.user.username : '';  // Obtiene el nombre de usuario si está disponible
        }
    },
    mutations: {
        setUser(state, userData) {
            console.log('Setting user:', userData);
            state.user = userData;
            state.isAuthenticated = true;
        },
        clearUser(state) {
            console.log('Clearing user');
            state.user = null;
            state.isAuthenticated = false;
        }
    },
  
    actions: {
        login({ commit }, user) {
            commit('setUser', user);
        },
        logout({ commit }) {  // Aceptar router como argumento
            commit('clearUser');
            // router.push('/iniciar-sesion');  // Usar router para redirigir
            // 
            // this.$router.push('/');
            // 
        },

    }
  
});
